import { graphql, useStaticQuery } from "gatsby"

// Import Images
import AppStore from "../images/badges/app-store.svg"
import ContactDemo from "../components/contactDemo"
import GooglePlay from "../images/badges/google-play.svg"
import Img from "gatsby-image"
// Import Components
import Layout from "../components/layout"
import { Link } from "gatsby"
import React from "react"
// Video Player
import ReactPlayer from "react-player"
import SEO from "../components/seo"

const Tab = () => {
  const data = useStaticQuery(graphql`
    query {
      phoneAppHero: file(relativePath: { eq: "phones/Rooam-App-Hero.jpg" }) {
        id
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      rooam_app_location_android: file(
        relativePath: { eq: "phones/Rooam-App-Android-Location.png" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      iPhoneHardware: file(relativePath: { eq: "phones/iPhone-hardware.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      rooam_app_apple_pay: file(
        relativePath: { eq: "phones/Rooam-App-Livefeed-Apple-Pay-Face-ID.png" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      rooam_app_receipt: file(
        relativePath: { eq: "phones/Rooam-App-Livefeed-Complete.png" }
      ) {
        id
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Rooam Tab - A contactless payment solution offering 100% walk-out protection"
        description="No need to present a check to guests or manually close out their tabs."
      />
      <div className="top-message text-center background-dark-grey">
        <p className="no-top-bottom small font-weight-400 text-light-grey">
          No app, no worries. View our web solution,{" "}
          <Link to="/pay/" className="text-link-white font-weight-600">
            Rooam Pay
          </Link>
        </p>
      </div>
      <div className="section no-bottom">
        <div className="container medium text-center">
          <h1 className="text-white no-top">Rooam Tab</h1>
          <p className="lead text-light-grey no-bottom">
            A contactless payment solution offering 100% walk-out protection
            with no need to present a check to guests or manually close out
            their tabs.
          </p>
          <span className="badge no-bottom-margin">
            <a
              href="https://apps.apple.com/us/app/rooam/id1141914731"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={AppStore} alt="App Store badge" />
            </a>
          </span>
          <span className="badge no-bottom-margin">
            <a
              href="https://play.google.com/store/apps/details?id=co.rooam.rooamapp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={GooglePlay} alt="Google Play badge" />
            </a>
          </span>
        </div>
      </div>

      <div className="section no-bottom">
        <div className="container">
          <Img
            fluid={data.phoneAppHero.childImageSharp.fluid}
            alt="Two phones featuring the Rooam app"
            loading="eager"
          />
        </div>
      </div>

      <div className="section steps">
        <div className="container">
          <h3 className="more text-white no-top">How it works</h3>
        </div>
        <div className="container columns mobile-columns">
          <div className="row clearfix">
            <div className="column one-four break-half">
              <div className="padding">
                <span className="step-circle blue no-top-margin">
                  <span className="step-number">
                    <p className="small text-white font-weight-600 no-top-bottom">
                      1
                    </p>
                  </span>
                </span>
                <p className="text-white font-weight-700 no-top-bottom">
                  Open tab
                </p>
                <p className="small text-light-grey no-bottom">
                  Guests select the location within the Rooam app, automatically
                  generating a tab in the existing POS.
                </p>
              </div>
            </div>
            <div className="column one-four break-half">
              <div className="padding">
                <span className="step-circle purple no-top-margin">
                  <span className="step-number">
                    <p className="small text-white font-weight-600 no-top-bottom">
                      2
                    </p>
                  </span>
                </span>
                <p className="text-white font-weight-700 no-top-bottom">
                  Order
                </p>
                <p className="small text-light-grey no-bottom">
                  They then order with a bartender/server like normal and say
                  they are using Rooam.
                </p>
              </div>
            </div>
            <div className="column one-four break-half">
              <div className="padding">
                <span className="step-circle pink no-top-margin">
                  <span className="step-number">
                    <p className="small text-white font-weight-600 no-top-bottom">
                      3
                    </p>
                  </span>
                </span>
                <p className="text-white font-weight-700 no-top-bottom">
                  Add items in POS
                </p>
                <p className="small text-light-grey no-bottom">
                  Staff simply add items to their open tab and serve as normal.
                  Guests see a live update of ordered items on the app.
                </p>
              </div>
            </div>
            <div className="column one-four break-half">
              <div className="padding">
                <span className="step-circle orange no-top-margin">
                  <span className="step-number">
                    <p className="small text-white font-weight-600 no-top-bottom">
                      4
                    </p>
                  </span>
                </span>
                <p className="text-white font-weight-700 no-top-bottom">
                  Guest closes tab
                </p>
                <p className="small text-light-grey no-bottom">
                  Guests select a tip and closes their tab using the Rooam app.
                  The location gets paid directly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section no-top-bottom">
        <div className="container">
          <div className="player-wrapper video-rounded video-border no-select">
            <ReactPlayer
              url="https://media.rooam.co/video/b2b/Rooam-App.mp4"
              className="react-player video-rounded"
              playing
              width="100%"
              height="100%"
              muted={true}
              controls={true}
              light="../videos/posters/Rooam-App.jpg"
              playIcon={
                <div className="play-btn">
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="svg-icon"
                      viewBox="0 0 24 24"
                    >
                      <path d="M3 22v-20l18 10-18 10z" />
                    </svg>
                  </span>
                </div>
              }
            />
          </div>
        </div>
        <div className="container">
          <p className="small text-grey text-center">
            Watch an overview of how Rooam Tab works
          </p>
        </div>
      </div>

      <div className="section">
        <div className="container">
          <div className="row clearfix">
            <div className="column break-full sticky">
              <div
                className="padding more float-middle"
                style={{ maxWidth: 400 }}
              >
                <h2 className="text-grey font-weight-400">
                  <span className="text-white font-weight-700">
                    Rooam Links
                  </span>{" "}
                  &mdash; Direct your guests to any URL of your choosing.
                </h2>
                <ul className="extras">
                  <li>
                    <span className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-icon"
                        viewBox="0 0 24 24"
                      >
                        <path d="M0 3.795l2.995-2.98 11.132 11.185-11.132 11.186-2.995-2.981 8.167-8.205-8.167-8.205zm18.04 8.205l-8.167 8.205 2.995 2.98 11.132-11.185-11.132-11.186-2.995 2.98 8.167 8.206z" />
                      </svg>
                    </span>
                    <p className="text-grey no-top-bottom">
                      <span className="font-weight-600 text-white">
                        Touchless Menu.
                      </span>{" "}
                      Remove the need for disposable or traditional menus at the
                      table or bar.
                    </p>
                  </li>
                  <li>
                    <span className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-icon"
                        viewBox="0 0 24 24"
                      >
                        <path d="M0 3.795l2.995-2.98 11.132 11.185-11.132 11.186-2.995-2.981 8.167-8.205-8.167-8.205zm18.04 8.205l-8.167 8.205 2.995 2.98 11.132-11.185-11.132-11.186-2.995 2.98 8.167 8.206z" />
                      </svg>
                    </span>
                    <p className="text-grey no-top-bottom">
                      <span className="font-weight-600 text-white">
                        Event Calendar or Ticket Sales.
                      </span>{" "}
                      Make guests aware of experiences they should come back for
                      or boost exposure for ticket sales.
                    </p>
                  </li>
                  <li>
                    <span className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-icon"
                        viewBox="0 0 24 24"
                      >
                        <path d="M0 3.795l2.995-2.98 11.132 11.185-11.132 11.186-2.995-2.981 8.167-8.205-8.167-8.205zm18.04 8.205l-8.167 8.205 2.995 2.98 11.132-11.185-11.132-11.186-2.995 2.98 8.167 8.206z" />
                      </svg>
                    </span>
                    <p className="text-grey no-top-bottom">
                      <span className="font-weight-600 text-white">
                        Loyalty &amp; Email Signup.
                      </span>{" "}
                      Increase guest engagement by building your email lists and
                      offering them rewards.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="column break-full">
              <div
                className="padding more float-middle"
                style={{ maxWidth: 400 }}
              >
                <Img
                  fluid={data.rooam_app_location_android.childImageSharp.fluid}
                  alt="Rooam App in an Android phone showcasing one of the Emmy Squared locations"
                  loading="eager"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section no-top-bottom">
        <div className="container">
          <div className="row clearfix">
            <div className="column break-full">
              <div
                className="padding more float-middle"
                style={{ maxWidth: 400 }}
              >
                <div className="phone-hardware">
                  <div className="hardware-screen background-dark">
                    <div className="player-wrapper tall">
                      <ReactPlayer
                        url={[
                          {
                            src: "../videos/Rooam-App-Flow.mp4",
                            type: "video/mp4",
                          },
                          {
                            src: "../videos/Rooam-App-Flow.webm",
                            type: "video/webm",
                          },
                        ]}
                        className="react-player"
                        playing
                        width="100%"
                        height="100%"
                        loop={true}
                        playsinline={true}
                        muted={true}
                      />
                    </div>
                  </div>
                  <Img
                    fluid={data.iPhoneHardware.childImageSharp.fluid}
                    alt="iPhone hardware shell to host the animating video"
                    loading="eager"
                  />
                </div>
              </div>
            </div>
            <div className="column break-full">
              <div
                className="padding more no-bottom float-middle"
                style={{ maxWidth: 405 }}
              >
                <h2 className="text-grey font-weight-400">
                  <span className="text-white font-weight-700">
                    Truly Contactless
                  </span>{" "}
                  &mdash; No need for receipts, QR codes, table tops, or touch
                  screens.
                </h2>
                <ul className="extras no-bottom">
                  <li>
                    <span className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-icon"
                        viewBox="0 0 24 24"
                      >
                        <path d="M0 3.795l2.995-2.98 11.132 11.185-11.132 11.186-2.995-2.981 8.167-8.205-8.167-8.205zm18.04 8.205l-8.167 8.205 2.995 2.98 11.132-11.185-11.132-11.186-2.995 2.98 8.167 8.206z" />
                      </svg>
                    </span>
                    <p className="text-grey no-top-bottom">
                      <span className="font-weight-600 text-white">
                        Reduce Risk Of COVID Transmission.
                      </span>{" "}
                      Rooam reduces up to 900 possible transmission points for
                      every 100 guests you serve.{" "}
                      <a
                        href="https://media.rooam.co/doc/Rooam-ReduceRisk.pdf"
                        target="_blank"
                        className="text-link-green font-weight-500"
                        rel="noopener noreferrer"
                      >
                        Learn More
                      </a>
                    </p>
                  </li>
                  <li>
                    <span className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-icon"
                        viewBox="0 0 24 24"
                      >
                        <path d="M0 3.795l2.995-2.98 11.132 11.185-11.132 11.186-2.995-2.981 8.167-8.205-8.167-8.205zm18.04 8.205l-8.167 8.205 2.995 2.98 11.132-11.185-11.132-11.186-2.995 2.98 8.167 8.206z" />
                      </svg>
                    </span>
                    <p className="text-grey no-top-bottom">
                      <span className="font-weight-600 text-white">
                        Increase Guest Confidence.
                      </span>{" "}
                      Local officials, your staff, and guests want significant
                      safety measures in place for when businesses are allowed
                      to re-open.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section no-bottom">
        <div className="container">
          <div className="row clearfix">
            <div className="column break-full sticky">
              <div
                className="padding more no-top float-middle"
                style={{ maxWidth: 400 }}
              >
                <h2 className="text-grey font-weight-400">
                  <span className="text-white font-weight-700">
                    100% Walkout Proof
                  </span>{" "}
                  &mdash; Guest’s payment is verified and linked to their tab
                  before ordering, so staff can always close the tab from the
                  POS to guarantee payment &amp; tip.
                </h2>
                <ul className="extras">
                  <li>
                    <span className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-icon"
                        viewBox="0 0 24 24"
                      >
                        <path d="M0 3.795l2.995-2.98 11.132 11.185-11.132 11.186-2.995-2.981 8.167-8.205-8.167-8.205zm18.04 8.205l-8.167 8.205 2.995 2.98 11.132-11.185-11.132-11.186-2.995 2.98 8.167 8.206z" />
                      </svg>
                    </span>
                    <p className="text-grey no-top-bottom">
                      <span className="font-weight-600 text-white">
                        Offer Apple Pay &amp; Google Pay.
                      </span>{" "}
                      Accept Apple Pay and Google Pay with no extra hardware or
                      fees.
                    </p>
                  </li>
                  <li>
                    <span className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-icon"
                        viewBox="0 0 24 24"
                      >
                        <path d="M0 3.795l2.995-2.98 11.132 11.185-11.132 11.186-2.995-2.981 8.167-8.205-8.167-8.205zm18.04 8.205l-8.167 8.205 2.995 2.98 11.132-11.185-11.132-11.186-2.995 2.98 8.167 8.206z" />
                      </svg>
                    </span>
                    <p className="text-grey no-top-bottom">
                      <span className="font-weight-600 text-white">
                        Increased Payment Security, Reduced Liability &
                        Chargebacks.
                      </span>{" "}
                      Rooam transactions are validated Level 1 PCI DSS
                      compliant. Your guests' personal payment information will
                      never touch your POS system.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="column break-full">
              <div
                className="padding more float-middle"
                style={{ maxWidth: 400 }}
              >
                <Img
                  fluid={data.rooam_app_apple_pay.childImageSharp.fluid}
                  alt="Rooam App with Apple Pay"
                  loading="eager"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section more">
        <div className="container text-center">
          <h1 className="text-white no-top">
            &ldquo;We've had no pushback and 100% of our guests have been
            downloading Rooam.&rdquo;
          </h1>
          <p className="text-grey no-bottom">
            <span className="font-weight-600">Mikhail Schilkey</span> &mdash; GM
            at Longman and Eagle
          </p>
        </div>
      </div>

      <div className="section no-top">
        <div className="container">
          <h3 className="more text-grey no-top" style={{ maxWidth: 480 }}>
            <span className="text-white">Customer Feedback</span> &mdash; What
            some guests say about Rooam Tab
          </h3>
        </div>
        <div className="container columns">
          <div className="row clearfix">
            <div className="column one-three break-full">
              <div className="padding">
                <div className="box background-dark-grey">
                  <div className="box-padding less">
                    <p className="small text-white font-weight-600 no-top-bottom">
                      &ldquo;Used your app for the first time at The Anthem in
                      DC last night. So amazing!! Much easier and faster than
                      carrying a tab or paying as you go. Brilliant!!!&rdquo;
                    </p>
                    <p className="small text-grey font-weight-400 no-bottom">
                      Michelle P.
                    </p>
                  </div>
                </div>
              </div>
              <div className="padding">
                <div className="box background-dark-grey">
                  <div className="box-padding less">
                    <p className="small text-white font-weight-600 no-top-bottom">
                      &ldquo;Love this app! Makes the bar experience so much
                      easier by being able to close out the tab right on your
                      phone. No more waiting in line to close out. Get this
                      app!&rdquo;
                    </p>
                    <p className="small text-grey font-weight-400 no-bottom">
                      Laurie D.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="column one-three break-full">
              <div className="padding">
                <div className="box background-dark-grey">
                  <div className="box-padding less">
                    <p className="small text-white font-weight-600 no-top-bottom">
                      &ldquo;Wow! How didn’t anybody make this before?! Genius.
                      We used this app last night, made the bar experience
                      seamless, we left a big tip and walked right out! I wish
                      every bar had this!&rdquo;
                    </p>
                    <p className="small text-grey font-weight-400 no-bottom">
                      Libby B.
                    </p>
                  </div>
                </div>
              </div>
              <div className="padding">
                <div className="box background-dark-grey">
                  <div className="box-padding less">
                    <p className="small text-white font-weight-600 no-top-bottom">
                      &ldquo;I really loved not having to go back to close the
                      tab at the end of the night, surrounded by a million other
                      people doing the exact same thing.&rdquo;
                    </p>
                    <p className="small text-grey font-weight-400 no-bottom">
                      Ben K.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="column one-three break-full">
              <div className="padding">
                <div className="box background-dark-grey">
                  <div className="box-padding less">
                    <p className="small text-white font-weight-600 no-top-bottom">
                      &ldquo;It’s incredibly easy to use. I felt so ahead of the
                      curve just showing my phone and not digging out my card,
                      having to do math (YUCK!) to determine a tip and signing a
                      paper copy. This is space age stuff, good work!&rdquo;
                    </p>
                    <p className="small text-grey font-weight-400 no-bottom">
                      Harry H.
                    </p>
                  </div>
                </div>
              </div>
              <div className="padding">
                <div className="box background-dark-grey">
                  <div className="box-padding less">
                    <p className="small text-white font-weight-600 no-top-bottom">
                      &ldquo;Delightfully easy - almost too easy, judging by my
                      bar tab at the end of the night!&rdquo;
                    </p>
                    <p className="small text-grey font-weight-400 no-bottom">
                      Luke J.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section no-top-bottom">
        <div className="container medium text-center">
          <h1 className="text-white no-top-bottom">Request a demo</h1>
        </div>
      </div>

      <div className="section no-top">
        <ContactDemo formName="request-demo-tab" />
      </div>
    </Layout>
  )
}

export default Tab
